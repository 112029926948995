<template>
	<div class="app-box">
		<div class="title-box">
			<div class="line"></div>
			<p class="title">服务列表</p>
		</div>
		<a-divider style="margin-left: -10px" />
		<a-form-model class="form-search" :colon="false" layout="inline" :model="query" labelAlign="left"
			@submit="handleSearch" @submit.native.prevent>
			<a-row>
				<a-form-model-item style="float: right; margin-right: 0">
					<a-button type="primary" html-type="submit"> 查询 </a-button>
					<a-button @click="Reset">重置</a-button>
				</a-form-model-item>
				<a-form-model-item label="类型">
					<a-select placeholder="请选择类型" v-model="query.class_id">
						<a-select-option :value="0">
							全部
						</a-select-option>
						<a-select-option :value="item.class_id" v-for="item in levelList" :key="item.class_id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item label="服务名称">
					<a-input v-model="query.name" placeholder="请输入姓名"></a-input>
				</a-form-model-item>
			</a-row>
		</a-form-model>
		<a-row class="table-action-list" style="margin-top: 20px">
			<a-button type="primary" @click="add" style="margin-left: 10px">+新增服务</a-button>
			<a-button type="cancel" @click="toClass" style="float: right; margin-right: 0">服务类型</a-button>
		</a-row>
		<a-table class="main-table" :pagination="{
			current: query.page,
			total: pagetotal,
			showTotal: (pagetotal, range) => `共 ${pagetotal} 条数据`,
		}" @change="listChange" :columns="columns" :data-source="data" rowKey="equipment_id" style="margin-top: 14px"
			v-viewer>
			<span slot="member_card" slot-scope="text, record">
				{{ record.member_card != "" ? record.member_card : '非会员' }}
			</span>
			<span slot="class_id" slot-scope="text, record">
				{{ getName(levelList, record.class_id, "class_id", "name") }}
			</span>
			<span slot="content" slot-scope="text, record">
				<a-tooltip placement="topLeft" style="float: left;" arrow-point-at-center v-if="record.content.length > 20">
					{{ record.content.substring(0, 20) + "..." }}
					<template slot="title">
						<span>{{ record.content }}</span>
					</template>
				</a-tooltip>
				<template v-if="record.content.length <= 20">
					{{ record.content }}
				</template>
			</span>
			<span slot="money" slot-scope="text, record">
				{{ record.money }} 元
			</span>
			<span slot="package" slot-scope="text, record">
				<a @click="toPackage(record)">查看套餐</a>
			</span>
			<span slot="action" slot-scope="text, record">
				<a-space>
					<a @click="toEdit(record)">编辑</a>
					<a-popconfirm title="确认删除" ok-text="是" cancel-text="否" @confirm="del(record.service_id)">
						<a style="color: #E0352B">删除</a>
					</a-popconfirm>
				</a-space>
			</span>
		</a-table>

		<!-- 抽屉1 添加/修改 -->
		<a-drawer :title="(customer_id == null ? '新增' : '编辑') + '服务'" :width="720" :visible="visible1"
			:body-style="{ paddingBottom: '80px' }" @close="onClose1">

			<a-form-model ref="customerForm" :model="customerForm" :rules="rules" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item ref="name" label="服务名称" prop="name">
					<a-input v-model="customerForm.name" @blur="() => {
						$refs.name.onFieldBlur();
					}
						" placeholder="请输入" />
				</a-form-model-item>
				<a-form-model-item label="服务类型" prop="class_id">
					<a-select placeholder="请选择类型" v-model="customerForm.class_id">
						<a-select-option :value="item.class_id" v-for="item in levelList" :key="item.class_id">
							{{ item.name }}
						</a-select-option>
					</a-select>
				</a-form-model-item>
				<a-form-model-item ref="money" label="服务费用" prop="money">
					<a-input suffix="元" v-model="customerForm.money" @blur="() => {
						$refs.name.onFieldBlur();
					}
						" placeholder="请输入" :step="0.01" />
				</a-form-model-item>
				<a-form-model-item label="服务介绍" prop="content">
					<a-input v-model="customerForm.content" type="textarea" placeholder="请输入"
						:autosize="{ minRows: 2, maxRows: 100 }" />
				</a-form-model-item>
			</a-form-model>

			<div :style="{
				position: 'absolute',
				right: 0,
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e9e9e9',
				padding: '10px 16px',
				background: '#fff',
				textAlign: 'right',
				zIndex: 1,
			}
				">
				<a-button :style="{ marginRight: '8px' }" @click="onClose1">
					取消
				</a-button>
				<a-button type="primary" @click="submitForm">
					确定
				</a-button>
			</div>
		</a-drawer>


		<!-- 抽屉2 服务类型 -->
		<a-drawer title="查看分类" :width="750" :visible="visible2" :body-style="{ paddingBottom: '80px' }" @close="onClose2">
			<a-row class="table-action-list" style="margin-top: 20px">
				<a-button type="primary" @click="addClass" style="margin-left: 10px">+新增分类</a-button>
			</a-row>
			<a-table class="main-table" :pagination="{
				current: query.page,
				total: classPagetotal,
				showTotal: (classPagetotal, range) => `共 ${classPagetotal} 条数据`,
			}" @change="classListChange" :columns="classColumns" :data-source="classList" rowKey="class_id"
				style="margin-top: 14px" v-viewer>
				<span slot="action" slot-scope="text, record">
					<a-space>
						<a @click="toEditClass(record)">编辑</a>
						<a-popconfirm title="确认删除" ok-text="是" cancel-text="否" @confirm="delClass(record.class_id)">
							<a style="color: #E0352B">删除</a>
						</a-popconfirm>
					</a-space>
				</span>
			</a-table>
			<div :style="{
				position: 'absolute',
				right: 0,
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e9e9e9',
				padding: '10px 16px',
				background: '#fff',
				textAlign: 'right',
				zIndex: 1,
			}
				">
				<a-button :style="{ marginRight: '8px' }" @click="onClose2">
					取消
				</a-button>
				<a-button type="primary" @click="onClose2">
					确定
				</a-button>
			</div>
		</a-drawer>

		<!-- 抽屉3 服务类型详情 -->
		<a-drawer :title="(class_id == null ? '新增' : '编辑') + '分类'" :width="650" :visible="visible3"
			:body-style="{ paddingBottom: '80px' }" @close="onClose3">

			<a-form-model ref="classForm" :model="classForm" :rules="rules2" :label-col="labelCol"
				:wrapper-col="wrapperCol">
				<a-form-model-item ref="name" label="名称" prop="name">
					<a-input v-model="classForm.name" @blur="() => {
						$refs.name.onFieldBlur();
					}
						" placeholder="请输入" />
				</a-form-model-item>
			</a-form-model>
			<div :style="{
				position: 'absolute',
				right: 0,
				bottom: 0,
				width: '100%',
				borderTop: '1px solid #e9e9e9',
				padding: '10px 16px',
				background: '#fff',
				textAlign: 'right',
				zIndex: 1,
			}
				">
				<a-button :style="{ marginRight: '8px' }" @click="onClose3">
					取消
				</a-button>
				<a-button type="primary" @click="submitClass">
					确定
				</a-button>
			</div>
		</a-drawer>
	</div>
</template>

<script>
import { domain } from '@/siteInfo.js'

export default {
	data() {
		let validateClass = (rule, value, callback) => {
			if (value) {
				callback(); // 通过验证
			} else {
				callback(new Error('请选择分类')); // 验证失败，返回错误信息
			}
		};
		let validateMoney = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入金额'));
			} else if (!/^\d+(\.\d{1,2})?$/.test(value)) {
				callback(new Error('请输入正确的金额'));
			} else {
				callback();
			}
		};
		return {
			query: {
				page: 1,
				limit: 10
			},
			classQuery: {
				page: 1,
				limit: 10
			},
			customer_id: null,
			pagetotal: 0,
			classPagetotal: 0,
			classList: [],
			checkValue: [],
			packageList: [],
			tags: ['Tag1', 'Tag2', 'Tag3'],
			inputValue: '',
			checkOptions: [
				{
					label: '使用中',
					value: 1
				},
				{
					label: '已完成',
					value: 2
				},
				{
					label: '已退款',
					value: 3
				}
			],
			visible1: false,
			visible2: false,
			visible3: false,
			labelCol: { span: 4 },
			wrapperCol: { span: 14 },
			other: '',
			customerForm: {
				name: '',
				money: null,
				content: '',
			},
			service_id: null,
			classForm: { name: '' },
			class_id: null,
			rules: {
				name: [
					{ required: true, message: '请输入服务名称', trigger: 'blur' },
				],
				money: [
					{ required: true, message: '请输入金额', trigger: 'blur' },
					{ validator: validateMoney, trigger: 'blur' },
				],
				class_id: [{ required: true, validator: validateClass, message: "请选择类型", trigger: 'change' }],
				content: [
					{ required: true, message: '请输入服务介绍', trigger: 'blur' },
					{ max: 255, message: '服务介绍仅限255字', trigger: 'blur' },
				],
			},
			rules2: {
				name: [
					{ required: true, message: '请输入名称', trigger: 'blur' },
				],
			},

			columns: [
				{
					title: '服务名称',
					dataIndex: 'name',
				},
				{
					title: '服务类型',
					scopedSlots: {
						customRender: 'class_id'
					}
				},
				{
					title: '服务介绍',
					scopedSlots: {
						customRender: 'content',
					},
					width: '40%'
				},
				{
					title: '服务费用',
					scopedSlots: {
						customRender: 'money'
					}
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action',
					},
				},
			],
			classColumns: [
				{
					title: '名称',
					dataIndex: 'name',
					customRender: (text, record, index) => {
						return {
							children: text,
							attrs: {
								style: 'width: 60%;', // 指定列宽度为 30%
							},
						};
					},
				},
				{
					title: '操作',
					scopedSlots: {
						customRender: 'action',
					},
				},],
			data: [],
			levelList: [],
			selectedRowKeys: []
		}
	},
	created() {
		this.getList()
		this.getLevelList()
	},
	methods: {
		toWenjuan(record) {
			this.$post(domain + '/admin/v1/customer/memberPackage', { "customer_id": record.customer_id }).then(res => {
				if (res.code === 0) {
					this.packageList = res.data
					this.visible2 = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		toClass() {
			this.getClassList()
			this.visible2 = true
		},
		toEditClass(record) {
			this.class_id = record.class_id
			this.$post(domain + '/admin/v1/serviceClass/info', { class_id: record.class_id }).then(res => {
				if (res.code === 0) {
					this.classForm = res.data
					this.visible3 = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		toEdit(record) {
			this.service_id = record.service_id
			this.$post(domain + '/admin/v1/service/info', { service_id: record.service_id }).then(res => {
				if (res.code === 0) {
					this.customerForm = res.data
					this.visible1 = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		delClass(class_id) {
			this.$post(domain + '/admin/v1/serviceClass/delete', { "class_id": class_id }).then(res => {
				if (res.code === 0) {
					this.$message.success("删除成功")
					this.getClassList()
					this.getLevelList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		submitForm() {
			this.$refs.customerForm.validate((valid) => {
				if (valid) {
					let action = "";
					if (this.service_id != null) {
						action = "update"
						this.customerForm.service_id = this.service_id
					} else {
						action = "add"
					}
					this.$post(domain + '/admin/v1/service/' + action, this.customerForm).then(res => {
						if (res.code === 0) {
							this.$message.success(res.msg)
							this.visible1 = false
							this.initForm()
							this.getList()
						} else {
							this.$message.error(res.msg)
						}
					})
				}
			});
		},
		submitClass() {
			this.$refs.classForm.validate((valid) => {
				if (valid) {
					let action = "";
					if (this.class_id != null) {
						action = "update"
						this.classForm.class_id = this.class_id
					} else {
						action = "add"
					}
					this.$post(domain + '/admin/v1/serviceClass/' + action, this.classForm).then(res => {
						if (res.code === 0) {
							this.$message.success(res.msg)
							this.visible3 = false
							this.initClassForm()
							this.getClassList()
							this.getLevelList()
						} else {
							this.$message.error(res.msg)
						}
					})
				}
			});
		},

		addClass() {
			this.visible3 = true
		},
		getClassList() {
			this.$post(domain + '/admin/v1/serviceClass/list', this.classQuery).then(res => {
				if (res.code === 0) {
					this.classPagetotal = res.data.total
					this.classList = res.data.list
					this.visible2 = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		toPackage(record) {
			this.$post(domain + '/admin/v1/customer/memberPackage', { "customer_id": record.customer_id }).then(res => {
				if (res.code === 0) {
					this.packageList = res.data
					this.visible3 = true
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		onClose3() {
			this.visible3 = false
			this.initClassForm()
		},
		onClose2() {
			this.visible2 = false
		},
		initClassForm() {
			this.class_id = null
			this.classForm = {
				name: ''
			}
		},
		handleClose(removedTag) {
			const tags = this.customerForm.tag.filter(tag => tag !== removedTag);
			this.customerForm.tag = tags;
		},
		handleInputConfirm() {
			const inputValue = this.inputValue;
			let tags = JSON.parse(JSON.stringify(this.customerForm.tag));
			if (inputValue && tags.indexOf(inputValue) === -1) {
				tags = [...tags, inputValue];
			}
			this.customerForm.tag = tags;
			console.log("customerForm", this.customerForm.tag)
			this.inputValue = '';
		},
		Reset() {
			this.query = {
				page: 1,
				limit: this.query.limit
			}
			this.getList()
		},
		handleChange(value) {
			console.log(`Selected: ${value}`);
		},
		popupScroll() {
			console.log('popupScroll');
		},
		getName(arr, id, idName, lableName) { //通过id获取数组名称
			if (id || id == '0') {
				const index = arr.findIndex((role) => role[idName] === id)
				return index >= 0 ? arr[index][lableName] : undefined
			} else {
				return undefined
			}
		},
		del(service_id) {
			this.$post(domain + '/admin/v1/service/delete', { "service_id": service_id }).then(res => {
				if (res.code === 0) {
					this.$message.success("删除成功")
					this.getList()
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		add() {
			console.log("customer_id", this.customer_id)
			this.visible1 = true;
		},
		onClose1() {
			this.visible1 = false;
			this.initForm()
		},
		initForm() {
			this.service_id = null
			this.customerForm = {
				name: '',
				money: null,
				content: '',
			}
		},
		toOrder(item) {
			if (item.rent_count > 0) {
				this.$router.push({
					path: '/orderList',
					query: {
						cid: item.customer_id
					}
				})
			} else {
				this.$message.warn("暂未产生订单")
			}
		},
		getList() {
			this.$post(domain + '/admin/v1/service/list', this.query).then(res => {
				if (res.code === 0) {
					this.data = res.data.list
					this.pagetotal = res.data.total
				} else {
					this.$message.error(res.msg)
				}
			})
		},

		getLevelList() {
			this.$post(domain + '/admin/v1/serviceClass/listNoPage').then(res => {
				if (res.code === 0) {
					this.levelList = res.data
				} else {
					this.$message.error(res.msg)
				}
			})
		},
		listChange(e) {
			this.query.page = e.current
			this.getList()
		},
		classListChange(e) {
			this.classQuery.page = e.current
			this.getClassList()
		},
		handleSearch(query) {
			this.query.page = 1
			this.customer_id = null
			this.getList()
			console.log(query)
		}
	}
}
</script>

<style lang="less" scoped>
.title-box {
	display: flex;
	align-items: center;
	margin-left: 8px;

	.line {
		width: 5px;
		height: 14px;
		background-color: #4e80f8;
	}

	.title {
		color: #383f50;
		font-size: 16px;
		margin-left: 5px;
	}
}

.img-qr {
	height: 30px;
	cursor: pointer;
}
</style>

<style lang="less">
.form-search {
	.ant-form-item-label {
		width: 70px;
	}
}
</style>
